import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCyVyy0OHyIDDGqD4Gg0Zm1MIVSZ2sT9Q4",
  authDomain: "bain-79747.firebaseapp.com",
  projectId: "bain-79747",
  storageBucket: "bain-79747.appspot.com",
  messagingSenderId: "454726278722",
  appId: "1:454726278722:web:687023951013eb99e657db",
  measurementId: "G-N0DL2ZQHEQ",
};

const app = initializeApp(firebaseConfig);

// Get a reference to the database service
export default  getDatabase(app);
