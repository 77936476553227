import React, { useState, useEffect } from "react";
import axios from "axios";
import { FadeLoader } from "react-spinners";
import { Navbar } from "../../components";
import { useNavigate } from "react-router-dom";

function Home(props) {
  const [loading, setLoading] = useState(true);
  const [surveyData, setSurveyData] = useState();

  const navigate = useNavigate();

  // axios call api for data
  let getJsonData = async () => {
    await axios
      .get(`./config.json`)
      .then((res) => {
        const response = res.data;
        // console.log("api data =====> ", response);
        setLoading(false);
        setSurveyData(response);
      })
      .catch((er) => {
        setLoading(false);
      });
  };

  // Start Quiz

  let startQuiz = () => {
    navigate("/quiz", {
      state: {
        surveyData,
      },
    });
  };

  useEffect(() => {
    getJsonData();
  }, []);

  return (
    <div className="w-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center h-screen">
          <FadeLoader
            height={20}
            width={5}
            radius={2}
            margin={4}
            color="#CC0000"
          />
        </div>
      ) : (
        <div>
          <Navbar
            path={
              surveyData && surveyData.companyInfo && surveyData.companyInfo.url
            }
          />
          <div className="mainBackground"
            style={{
              backgroundImage: `url(${surveyData && surveyData.companyInfo.bg
                })`,
              // backgroundImage: `url(${
              //   surveyData && surveyData.companyInfo.bg
              // })`,
              height: "100%",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="text-center">
              <div
                style={{
                  backgroundColor: "rgba(0,0,0,0.5)",
                }}
                className="h-screen px-4 py-6 xl:pt-[15%] lg:pt-[15%] md:pt-[15%] pt-[25%] "
              >
                <h2 className=" my-7 2xl:text-7xl lg:text-7xl md:text-7xl text-5xl text-center mx-2 font-bold leading-1 text-white">
                  {surveyData && surveyData.companyInfo.title}
                </h2>
                <p
                  style={{ whiteSpace: "pre-line" }}
                  className="text-center 2xl:text-2xl lg:text-xl md:text-lg text-base text-white"
                >
                  {surveyData && surveyData.companyInfo.description}
                </p>
                <div className="flex justify-center 2xl:my-6 my-4">
                  <p className="2xl:w-60 w-48 border border-[#cc0000] bg-[#cc0000]"></p>
                </div>

                <button
                  onClick={() => startQuiz()}
                  className="md:w-1/3 w-10/12 rounded-md bg-[#CC0000] text-white font-bold lg:mt-0 md:mt-0 mt-6 py-3 px-10  border-none"
                >
                  {surveyData && surveyData.companyInfo.buttonText}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
