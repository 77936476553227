import React from "react";
import { useState } from "react";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";

function Navbar(props) {
  const [modalStatus, setmodalStatus] = useState("hidden");

  const showModal = () => {
    setmodalStatus("");
  };

  const closeModal = () => {
    setmodalStatus("hidden");
  };
  return (
    <>
      <div className="w-full flex justify-between items-center bg-[#CC0000] py-4 px-10">
        <div className="empty bg-slate-50 w-[3rem]"></div>
        <img
          alt="logo"
          className="lg:w-3/12 md:w-3/12 w-7/12"
          src={`${props.path}`}
        />

        {props.hideModal ? (
          <div className="btn"></div>
        ) : (
          <div className="btn">
            <QuestionMarkCircleIcon
              className="w-10 h-10 text-white cursor-pointer"
              onClick={showModal}
            />
          </div>
        )}
      </div>
      <div
        id="popup-modal"
        tabIndex={-1}
        className={`${modalStatus} fixed z-50  2xl:top-[17rem] xl:top-[17rem] lg:top-[17rem] md:top-[17rem] top-[16rem] 2xl:right-20 xl:right-20 lg:right-20 md:right-20 right-0  `}
      >
        <div className="relative p-4 w-full max-w-md h-full md:h-auto">
          <div className="relative bg-[#CC0000]  rounded-lg shadow ">
            <button
              onClick={closeModal}
              type="button"
              className="absolute top-3 right-2.5 text-white bg-transparent hover:bg-white hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              data-modal-toggle="popup-modal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="p-6 text-center">
              <div className="flex justify-center items-center mb-4">
                <QuestionMarkCircleIcon className="w-10 h-10 text-white cursor-pointer" />
              </div>
              <h3 className="mb-5 text-xl font-normal text-white">
                Not sure where you fit at Bain? Answer these 10 questions and
                we'll suggest the best role for you.
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
