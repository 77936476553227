import { push, ref } from "firebase/database";
import { postApi } from "../../services/apiCall";
import db from "../../firebase";

// it will shuffle the array of answers for every question
// 1) question, 2) array of answers, 3)background image url, type ==> {single select or multiselect so we can show the component as per need}

const shuffleArray = (question, array, url, type) => {
  for (var i = array.length - 1; i > 0; i--) {
    // Generate random number
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  let singleQuestion = {
    question,
    options: array,
    url,
    type,
  };

  return singleQuestion;
};

// handle data response

let resutlFun = async (result, resultCardData) => {
  let data = result;
  let apiCallRequest = {
    // url:'https://vsrf2dikkuqeshnd266xyfajzu0njbns.lambda-url.us-east-1.on.aws/',
    url:'https://ujagar2.ca/scorev2',
    data,
  };

  return postApi(apiCallRequest)
    .then(async (res) => {
      let obj = res.data;
      console.log(obj,' here ist')
      let finalResult = [];

      finalResult.push(resultCardData[obj["First"]]);
      finalResult.push(resultCardData[obj["Second"]]);
      finalResult.push(resultCardData[obj["Third"]]);


      let completeResponseObj = {
        response: obj,
        result,
      };
      
      await push(ref(db, "data/"), completeResponseObj);
      
      return {
        loading: false,
        finalResult,
      };
    })
    .catch((er) => {
      return er;
    });
};
export { shuffleArray, resutlFun };
